/***
 * Simple Event Emitter that we use when updating the main navigation to trigger a response
 * in our FilterComponent classes.
 * 
 */
export const EventEmitter = {    
    _events: {},    
    dispatch: function (event, data) {        
      if (!this._events[event]) return;             
      this._events[event].forEach(callback => callback(data))    
    },    
    subscribe: function (event, callback) {      
      if (!this._events[event]) this._events[event] = [];        
      this._events[event].push(callback);    
  }} 
