const statesMap = {
  "Australian Capital Territory": "ACT",
  "ACT": "ACT",
  "Northern Territory": "NT",
  "NT": "NT",
  "Victoria": "VIC",
  "VIC": "VIC",
  "Queensland": "Qld",
  "QLD": "Qld",
  "Tasmania": "TAS",
  "TAS": "TAS",
  "New South Wales": "NSW",
  "NSW": "NSW",
  "Western Australia": "WA",
  "WA": "WA",
  "South Australia": "SA",
  "SA": "SA",
  "Etelä-Australia": "SA" // for some reason this is a thing
}

export default statesMap
