import React from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'

/* A custom filter popup (or popdown component) 
  Accepts the following properties
  name: The name of this component - the name will be added as a css classname for the containing element 
  selectedValues: an array of values that will display as selected
  pos: a map containing the left position and calculated width of this dropbox
  onFilterValue: a callback function that is called when a value is selected
  onPopupCloseClicked: a callback function that is called when the popup is closed
*/
class FilterPopup extends React.Component {
  constructor(props){
    
    super(props)
    this.listContainer = React.createRef();
    console.log(props.selectedValues)
    this.state = {
      selectedValues: props.selectedValues || [],
      index: -1,
      styles: {
        left: 0,
        top: 0,
        width: 200
      }
    }
  }

  componentDidMount(){
    if (document.documentElement.clientWidth  > 400){
      let el = ReactDOM.findDOMNode(this)
      this.setState({
        styles: {
          left: this.props.pos.left - 20, //+ (this.props.pos.width / 2) - (el.offsetWidth / 2),
          top: 0,
          width: this.props.pos.width + 40
        }
      })
    }

    this._handleKeys =  this.handleKeys.bind(this)
    document.body.addEventListener('keydown', this._handleKeys)
  }

  componentWillUnmount(){
    document.body.removeEventListener('keydown', this._handleKeys)
  }

  handleClickOutside(e){
    e.preventDefault();
    this.props.onPopupCloseClicked()
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      selectedValues: nextProps.selectedValues
    })
 
  }


  handleKeys(ev){
    let newIndex = 0;
    if(ev.which == 27){
      this.props.onPopupCloseClicked();
      return;
    } else if (ev.which == 13){ // enter
      let filterItem = this.props.values[this.state.index]
      this.props.onFilterValue(filterItem);
      return;
    } else if (ev.which == 40){ // down arrow
      if (this.state.index < this.props.values.length - 1){
        newIndex = this.state.index + 1
      } else {
        newIndex = 0;
      }
    } else if (ev.which == 38){
      if (this.state.index > 0){
        newIndex = this.state.index - 1
      } else{
        newIndex = this.props.values.length - 1;
      }
    }

    this.setState({
      index: newIndex
    }, () => {
      this.listContainer.current.scrollTop = document.getElementById('popup-' + newIndex).offsetTop;
    })

    ev.preventDefault();
  }

  /* Determine whether this item is selected */
  isSelected(filterItem, index){
    let selectedValues = this.state.selectedValues;
    return filterItem.value !== null && filterItem.value.length > 0 &&
     selectedValues !== null && selectedValues.indexOf(filterItem.value) >= 0 || this.state.index === index; 
  }

  render(){

    return (
      <div className={"filter-popup clearfix "+ this.props.name} style={this.state.styles} >
        <div ref={this.listContainer} className={"filter-popup-contents " + this.props.popupLocation}>
          <div className="filter-popup-inner relative">
            <div className="clearfix sm-hide md-hide lg-hide">
              <a onClick={() => this.props.onPopupCloseClicked()} className="right close-filter-popup">
                <img src="/sites/all/themes/play2016/images/close-menu.png" alt="Close Dropdown" />
              </a>
            </div>
            <ul className="filter-popup-body" role="listbox" >
              {this.props.values.map((filterItem, index) => (
                <li className={this.isSelected(filterItem, index) ? "filter-value selected" : "filter-value" } key={index} id={'popup-'+ index} 
                  role="option" aria-selected={this.isSelected(filterItem, index)}>
                  <a onClick={(ev)=> {this.props.onFilterValue(filterItem); ev.stopPropagation()} } dangerouslySetInnerHTML={{__html: filterItem.displayHTML}}></a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

const WrappedFilterPopup = onClickOutside(FilterPopup);

export default WrappedFilterPopup
