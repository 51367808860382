import Autocomplete from 'react-autocomplete'
import React from 'react'
import statesMap from './data/StatesMap'
import GeocodeService from './services/GeocodeService'
import onClickOutside from 'react-onclickoutside'
import { CLUB_FINDER_URL } from './data/Constants'

/**
 * The LocationAutocomplete component is an Autocomplete control that
 * takes a string value and returns a list of google places that the user can select from.
 * It takes the following properties
 * locationInputValue - a string value that will be queried upon
 * locationInputId - a google place id that can be used to return a location
 * hasButton - does this component have a button?
 * inputProps - properties for the autocomplet input
 * hasCancel - should we display a cancel button?
 * programFilter - the program filter object that will be used to send our query to the api
 * doFilterFocused - a callback function to call when the filter has been focussed on
 * 
 */
class LocationAutocomplete extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      locationInputValue: props.locationInputValue || '',
      locationInputId: props.locationInputId,
      locationInputItems: [],
      hasButton: props.hasButton || false,
      inputProps: props.inputProps || {},
      hasCancel: props.hasCancel || false,
      noResults: false,
      locationSet: false,
      programFilter: this.props.programFilter
    }

    this.state.inputProps['onFocus'] = this.onFocus.bind(this)

    this.inputStyles = {zIndex:110, 'textAlign': 'left', fontSize: '16px', backgroundColor: '#fff'};
    this.inputStylesHighlighted = {zIndex: 110, 'textAlign': 'left', backgroundColor: '#E6EBF3', fontSize: '16px'}
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocodeService = new GeocodeService();
  }

  componentDidUpdate(){
    if (this.props.isVisible){
      this.locationInput.focus()
    }
  }

  onFocus(e){
    this.locationInput.select();
    this.setState({locationInputValue: ''});
    if (this.props.doFilterFocused){
      this.props.doFilterFocused()
    }
  }

  doSearch(e){
    if (this.state.locationInputValue.length > 3){
      this.handleSuburbChange(e, this.state.locationInputValue)
    }
  }

  handleSuburbChange(e, value){
    this.setState({
      locationInputValue: value,
      locationInputId: null,
      locationSet: true
    }, () => {
      if (this.state.locationInputValue.length > 3){
        var options = {
          types: ['(regions)'],
          componentRestrictions: {country: 'au'},
          input: this.state.locationInputValue
        };
        this.autocompleteService.getPlacePredictions(options, this.getAutocompleteResults.bind(this))
      }
    })
  }

  suburbSelected(value, item){
    this.locationInput.refs.input.blur()
    // set the menu to only the selected item
    this.setState({
        locationInputValue:  item.name , locationInputId: item.place_id,
        locationSet: true
    }, ()=>{
      this.props.onSuburbSelected(item)
    })

  }

  clearAutocomplete(e){
    this.setState({
      locationInputValue: '',
      locationInputId: '',
      locationInputItems: []
    })
  }

  getAutocompleteResults(predictions, status) {

    if (status != google.maps.places.PlacesServiceStatus.OK) {
      return;
    }

    let results = []

    predictions.forEach(function(prediction){
      if (prediction['terms'].length == 3 || prediction['terms'].length == 4){
        let state = ""
        let stateVal = statesMap[prediction.terms[1].value]
        if (stateVal){
          state = ", " + stateVal
        }
        let result = {
          name: prediction.terms[0].value + state,
          place_id: prediction.place_id
        }
        results.push(result)
      }
    });

    this.setState({
      noResults: results.length === 0,
      locationInputItems: results
    })
  }

  renderDropdownItems(items) {
    return items.map((item, index) => {
        return item
    })
  }

  renderDropdown(items, value, style) {
    if (this.state.noResults){
      return (<span></span>)
    }
    else {
      return(
        <div className="autocomplete-items" style={this.menuStyle}>
          {this.renderDropdownItems(items)}
        </div>
      )
    }
  }

  handleAutocompleteEscape(ev){
    if(ev.which == 27){
      this.props.hideAutocomplete()
    }
  }

  handleClickOutside(e){
    // e.preventDefault();
    this.props.hideAutocomplete()
  }

  render(){
    if (! this.state.locationSet){
      this.state.locationInputValue = this.props.locationInputValue
    }
    let autocompleteClasses = "autocomplete-input-container location-autocomplete"
    if (this.props.isVisible){
      autocompleteClasses += " visible"
    }

    let nearbyHref = CLUB_FINDER_URL;
    if (this.state.programFilter != null && this.state.programFilter.gender != null && this.state.programFilter.gender == 'FEMALE')
      nearbyHref += "?gender=FEMALE";
    return (
      <div className="relative inline-block">
        <div className={autocompleteClasses} onKeyUp={this.handleAutocompleteEscape.bind(this)}>
          {this.state.hasButton &&
            <a className="autocomplete-button" onClick={this.doSearch.bind(this)}></a>
          }
          <Autocomplete
              ref={(input) => { this.locationInput = input; }}
              onChange={this.handleSuburbChange.bind(this)}
              onSelect={this.suburbSelected.bind(this)}
              menuStyle={this.autocompleteStyles}
              items={this.state.locationInputItems}
              value={this.state.locationInputValue}
              getItemValue={(item) => item.name}
              renderMenu={this.renderDropdown.bind(this)}
              autoHighlight={this.state.autocompleteAutoHighlight}
              inputProps={this.state.inputProps}
              renderItem={(item, isHighlighted) => (
                <div
                  style={isHighlighted ? this.inputStylesHighlighted : this.inputStyles}
                  key={item.place_id}
                  id={item.place_id}
                >{item.name}</div>
              )}
            />
            {this.state.hasCancel &&
              this.state.locationInputItems.length > 0 &&
              <a onClick={this.clearAutocomplete.bind(this)} className="cancel-autocomplete-icon"></a>
            }
            
        </div>
        {this.state.locationInputItems.length == 0 &&
          this.props.showNearby &&
          <div className="nearby-link-container">
            <a href={nearbyHref}>Nearby</a>
          </div>
        }
      </div>
    )
  }
}

const WrappedLocationAutocomplete = onClickOutside(LocationAutocomplete);

export default WrappedLocationAutocomplete