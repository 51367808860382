import { EventEmitter } from './events'


function activateClubLinks(searchClubsLink, searchLocationLink){
  if (searchClubsLink && searchLocationLink){
    EventEmitter.dispatch('searchTypeUpdated', 'club-search');
    searchClubsLink.classList.add('active');
    searchLocationLink.classList.remove('active');
  }
}

export function initNavSearch(){  
  let searchClubsLink = document.getElementById('nav-search-clubs');
  let searchLocationLink = document.getElementById('nav-search-locations');
  if (searchClubsLink){
    searchClubsLink.addEventListener('click', (ev) => {
      activateClubLinks(searchClubsLink, searchLocationLink)
      ev.preventDefault();
    });
  }

  if (searchLocationLink){
    searchLocationLink.addEventListener('click', (ev) => {
      EventEmitter.dispatch('searchTypeUpdated', 'location-search');
      searchClubsLink.classList.remove('active');
      searchLocationLink.classList.add('active');
      ev.preventDefault();
    });
  }
}

export function activateSearchClubsLink(){
  let searchClubsLink = document.getElementById('nav-search-clubs');
  let searchLocationLink = document.getElementById('nav-search-locations');
  activateClubLinks(searchClubsLink, searchLocationLink);
}

