
export const MOBILE_MAX_SIZE = 640

export const CLUB_FINDER_URL = "/club-finder/"

/** The default page to send users to if a club is still registered with Gameday */
export const GAMEDAY_DEFAULT_REGISTER_LINK = "https://app.gameday.app"; // TODO, add correct one

/**
 * Configure for our various environments.  These can be switched between for each page (Club Finder, Home Page) in the cms
 */
export const SEARCH_ENVIRONMENTS = {
  'TEST': {
    url: "https://search-lc-aflfinder-1-hmoktwuainaaet7dtsrt2qkaie.us-east-1.es.amazonaws.com/afl_playhq_organisation_testing_01/_search?size=30",
    user: "playaflfinder",
    pass: "im5W5G2d3XEhRm5HP30!",
    registerBaseUrl: "https://www.sit.playhq.com/afl/org/"
  },
  'SIT': {
    url: "https://search-afl-search-dev01-gfkdeu2ig2eg64g22ubvvfsozu.ap-southeast-2.es.amazonaws.com/afl_playhq_organisation_dev03/_search?size=30",
    user: "aflsearchdev",
    pass: "6LqNYwkoKn6GpgRLFMc0!",
    registerBaseUrl: "https://www.sit.playhq.com/afl/org/"
  },
  'UAT': {

    url: "https://search-afl-search-prod02-2nh7iycjey2eynnzjjggfxpwyq.ap-southeast-2.es.amazonaws.com/afl_playhq_organisation_prod01/_search?size=30", // note that it's just the index that has changed from SIT
    user: "aflsearchprod",
    pass: "GtNaNSPuTpTZcGkCJ7MY!",
    registerBaseUrl: "https://www.playhq.com/afl/org/"
  },
  'PROD': {
    url: "https://search-afl-search-prod01-nmnhxsgxntbw5gz4cfgilgioti.ap-southeast-2.es.amazonaws.com/afl_playhq_organisation_prod01/_search?size=30", // note that it's just the index that has changed from SIT
    user: "aflsearchprod",
    pass: "|*t7MHsoBfRF6FTE=3DS5",
    registerBaseUrl: "https://www.playhq.com/afl/org/"
  }
}

/** Default text for various parts of our system */
export const DEFAULT_CENTER_SPAN_TEXT = 'Enter a club or centre name';
export const DEFAULT_FORMAT_TEXT = 'How do you want to play?';
export const DEFAULT_LOCATION_SPAN_TEXT = 'Where do you want to play?';
export const FINDING_LOCATION_SPAN_TEXT = 'Finding Your location';

export const DEFAULT_QUERY_DISTANCE = "20km"

export const AUSKICK_PROGRAM_NAME = 'Auskick';
export const AFL9S_PROGRAM_NAME = 'AFL 9s';
export const LEAGUES_PROGRAM_NAME = 'Leagues';

export const SHOW_PROMOS_FILTER = false;
export const USE_PLAY_HQ_LOOKUP = true;
export const PLAY_HQ_REGO_BASE = 'https://www.playhq.com/afl/org/'; // NOTE: remove .uat for live

export const USE_PLAY_HQ = "use-play-hq";
export const USE_GAMEDAY = "use-gameday";

export const ACCESS_FOR_ALL_ABILITIES_PLAY = "ACCESS_FOR_ALL_ABILITIES_PLAY"
export const FEMALE_ONLY_PARTICIPANTS = "FEMALE_ONLY_PARTICIPANTS";

export const GENDER_MALE = 'MALE';
export const GENDER_FEMALE = 'FEMALE';
export const GENDER_ALL = 'ALL';
export const GENDER_MIXED = 'MIXED'; // to be confirmed

export const GENDER_TEAMS_MAP = {
  'MALE': "Male Teams",
  'FEMALE': "Female Teams",
  'ALL': "Ages:",
  'MIXED': "Mixed Teams" // todo?
}

/* Maps between old programs and new formats */
export const PROGRAM_FORMAT_MAP = {
  'Auskick': 'AUSKICK',
  'youth': 'COMMUNITY',
  'senior': 'COMMUNITY',
  'junior': 'COMMUNITY'
}

/** A Mapping for our various formats and their text and image names */
export const FORMAT_MAP = {
  'AUSKICK' : {
    logo: 'auskick.png',
    logo_2x: 'auskick_l.png',
    title: 'NAB AFL Auskick',
    imgTitle: 'Logo for Auskick',
    description: 'Fun for kids <span className="large-bull">&bull;</span> Age 5-12'
  },
  'COMMUNITY': {
    logo: 'community.png',
    logo_2x: 'community_l.png',
    title: 'Community Football',
    imgTitle: 'Logo for Community Football',
    description: 'Club <span className="large-bull">&bull;</span> Age 8+'
  },
  'AFL_9S': {
    logo: 'afl9s.png',
    logo_2x: 'afl9s_l.png',
    title: 'AFL 9s',
    imgTitle: 'Logo for AFL 9s',
    description: 'Social <span className="large-bull">&bull;</span> Age 16+'
  },
  'AFLX': {
    logo: 'aflx.png',
    logo_2x: 'aflx_l.png',
    title: 'AFLX',
    imgTitle: 'Logo for AFLX',
    description: 'Social <span className="large-bull">&bull;</span> Age 8-16'
  },
}

export const POSTCODE_MATCHES = {
  "Rural City of Wangaratta" : 3677,
  "Wodonga": 3690,
  "Bunbury": 6230
}

export const AU_CENTER_COORDINATES = {
  lat:-26.0651846,
  lng: 134.5345225
}

/* Where are our map images located? */
export const MAP_IMAGES_DIR = '/sites/all/themes/play2016/images/map/';

/** Set up our map pins and other items for google maps */
export const MAP_PIN = {
  url: MAP_IMAGES_DIR + 'map_pin@2x.png',
  size: new google.maps.Size(52, 74),
  scaledSize: new google.maps.Size(26, 37),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12, 2)
}

export const MAP_PIN_SELECTED = {
  url: MAP_IMAGES_DIR + 'map_pin_selected@2x.png',
  size: new google.maps.Size(52, 74),
  scaledSize: new google.maps.Size(26, 37),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12, 2)
}

export const MAP_PIN_STAR = {
  url: MAP_IMAGES_DIR + 'map_pin_star@2x.png',
  size: new google.maps.Size(52, 74),
  scaledSize: new google.maps.Size(26, 37),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12, 2)
}

export const MAP_PIN_STAR_SELECTED = {
  url: MAP_IMAGES_DIR + 'map_pin_star_selected@2x.png',
  size: new google.maps.Size(52, 74),
  scaledSize: new google.maps.Size(26, 37),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12, 2)
}

export const MAP_CLUSTER_STYLES = [
{
    textColor: 'white',
    url: MAP_IMAGES_DIR + 'Map_Circle_S.png',
    height: 56,
    width: 56,
    textSize: 20
  },
 {
    textColor: 'white',
    url: MAP_IMAGES_DIR + 'Map_Circle_M.png',
    height: 81,
    width: 81,
    textSize: 24
  },
 {
    textColor: 'white',
    url: MAP_IMAGES_DIR + 'Map_Circle_L.png',
    height: 106,
    width: 106,
    textSize: 30
  }
];
