
/**
Makes use of Google maps Geocoder to retrieve user's address details
*/
class GeocodeService
{
  getLocationForPostcode(postcode, receivedResultsFunc){
    let geocoder = new google.maps.Geocoder();
    this.processResultsFunc = receivedResultsFunc
    geocoder.geocode({
      componentRestrictions: {
        country: 'AU',
        postalCode: postcode
      }
    }, this.processGeocodeResults.bind(this))
  }

  getPlace(placeId, receivedPlaceFunc){
    this.receivedPlaceFunc = receivedPlaceFunc

    let places = new google.maps.places.PlacesService(document.getElementById('map-attributions'));
    places.getDetails({placeId: placeId}, this.getPlaceResults.bind(this))
  }

  getPlaceResults(result, status){
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      return;
    }
    
    if (result && this.receivedPlaceFunc){
      let processedResult = this.processGeocodeResult(result)
      this.receivedPlaceFunc.apply(this, [processedResult])
      this.receivedPlaceFunc = null;
    }
  }

  /**
    Once we have the lat/lng, we can determine the users suburb postcode and state,
    which we can then plug into sports tg's api
  */
  getAddressDetails(lat, lng, receivedAddressFunc) {
    let latlng = {lat: lat, lng: lng};
    this.processResultsFunc = receivedAddressFunc
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': latlng}, this.processGeocodeResults.bind(this))
  }

  processGeocodeResult(result){
    let address = { suburb: null };
    let center = {}
    if (result){
      for(let i=0; i<result.address_components.length; i++){
        let component = result.address_components[i];

        if (component.types[0] == 'locality' ||
          component.types[0] == 'political' ||
          component.types[0] == 'colloquial_area'){
            address.suburb = component.long_name;
        }
        else if(address.suburb == null && component.types[0] == 'administrative_area_level_2'){
          address.suburb = component.long_name;
        }
        else if (component.types[0] == 'administrative_area_level_1' ){
          address.state = component.short_name;
        }
        else if (component.types[0] == 'postal_code'){
          address.postcode = component.long_name;
        }

        center = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        }

      }
    }

    return {
      address: address,
      center: center
    }
  }

  processGeocodeResults(results, status) {
    console.log(results);
    if (status === 'OK') {
      let result = null;
      for(let i=0; i<results.length; i++){
        result = results[i];
        if (result.types[0] == 'street_address' ||
          result.types[0] == 'locality' ||
          result.types[0] == 'political' ||
          result.types[0] == 'postal_code'){
          break;
        }
      }

      let geocodeResult = this.processGeocodeResult(result)

      if (this.processResultsFunc){
        this.processResultsFunc.apply(this, [geocodeResult])
        this.processResultsFunc = null;
      }

    } else {
      console.log('Cannot determine location.')
      // could not get postcode or address
    }
  }
}

export default GeocodeService
