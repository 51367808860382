import { FORMAT_MAP } from "./Constants"
/**
Contains data for our filter elements
*/

const filterValues = {
  genders: [
    {value: null, displayHTML: 'Any Team', buttonDisplayName: 'Any'},
    {value: 'MALE', displayHTML: 'Male teams', buttonDisplayName: 'Male Teams'},
    {value: 'FEMALE', displayHTML: 'Female teams', buttonDisplayName: 'Female Teams'},
    //{value: 'MIXED', displayHTML: 'Mixed teams', buttonDisplayName: 'Mixed Teams'} 
  ],
  formats: [
    {value: '', displayHTML: '<h4>All Formats</h4>', buttonDisplayName: 'All Formats'},
    {value: 'AUSKICK', displayHTML: '<h4>'+FORMAT_MAP['AUSKICK'].title+'</h4><p>' + FORMAT_MAP['AUSKICK'].description + '</p>' , buttonDisplayName: 'AUSKICK'},
    {value: 'COMMUNITY', displayHTML: '<h4>'+FORMAT_MAP['COMMUNITY'].title+'</h4><p>' + FORMAT_MAP['COMMUNITY'].description + '</p>' , buttonDisplayName: 'COMMUNITY'},
    {value: 'AFLX', displayHTML: '<h4>'+FORMAT_MAP['AFLX'].title+'</h4><p>' + FORMAT_MAP['AFLX'].description + '</p>' , buttonDisplayName: 'AFLX'},
    {value: 'AFL_9S', displayHTML: '<h4>'+FORMAT_MAP['AFL_9S'].title+'</h4><p>' + FORMAT_MAP['AFL_9S'].description + '</p>' , buttonDisplayName: 'AFL_9S'},
  ],
  agesText: 'any age',
  genderText: 'any gender',
}

export default filterValues
