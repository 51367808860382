import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal';

Modal.setAppElement('#modal-container');
/**
 * A TransitionModal component that allows us to forward onto another page.
 * accepts the following properties
 * open - is the modal open?
 * destinationName - the name of the destination website
 * logoClass - a class name that can be used to display a logo in CSS
 * url - the url to transition to
 * 
 */
export default class TransitionModal extends React.Component {

    constructor(props){
        super(props)
    
        this.state = {
          modalIsOpen: props.open || false,
          destinationName: props.destinationName || "PlayHQ",
          logoClass: props.logoClass || "playhq",
          url: props.url || null,
          modalMessage: props.modalMessage || '',
        };
        this.transitionTimeout = setTimeout(this.onContinue.bind(this), 3000);
        this.closeTimeout = setTimeout(this.closeModal.bind(this), 10000);

    }
   

    closeModal(){
        clearTimeout(this.transitionTimeout);
        clearTimeout(this.closeTimeout);
        this.setState({modalIsOpen: false})
    }

    onContinue(){
        if (!window.open(this.state.url)){
            window.open(this.state.url);
        }
        clearTimeout(this.transitionTimeout);
        clearTimeout(this.closeTimeout);
        this.setState({
          modalIsOpen: false
        });
    }

    onAfterClose(){
        ReactDOM.unmountComponentAtNode(document.getElementById('modal-container'))
    }
    

    render(){
        return (
          <Modal
            isOpen={this.state.modalIsOpen}
            contentLabel="Taking you to Another Website"
            className="transition-modal modal"
            overlayClassName="modal-overlay"
            onRequestClose={this.closeModal.bind(this)}
            closeTimeoutMS={500}
            onAfterClose={this.onAfterClose.bind(this)}
          >
            <div className="modal-content">
              <a href="#" className="action-close-modal close-modal-button">
                <img
                  src="/sites/all/themes/play2016/images/close-menu.png"
                  alt="Close Menu"
                  onClick={this.closeModal.bind(this)}
                  alt="Close Menu"
                  className="close-modal right"
                />
              </a>
              <div className="modal-text center">
                <div className="logo-container">
                  <span
                    className="play-logo-blue"
                    aria-label="Play.afl Logo"
                  ></span>
                  <span className="right-arrow" aria-label="Right Arrow"></span>
                  <span
                    className={"modal-logo-inner " + this.state.logoClass}
                    aria-label={"logo for " + this.state.logoClass}
                  ></span>
                </div>
                {this.state.modalMessage &&
                        <p className="modal-small-text modal-login-text">
                            {this.state.modalMessage}
                        </p>
                    }
                    {! this.state.modalMessage &&
                        <p className="modal-small-text">
                            We’re passing you to {this.state.destinationName} to complete your registration
                        </p>
                    }
                <a
                  className="btn btn-primary btn-modal action-modal-go"
                  onClick={this.onContinue.bind(this)}
                  rel="nofollow"
                >
                  Continue
                </a>
                <a
                  onClick={this.closeModal.bind(this)}
                  className="action-cancel-transition cancel-link"
                >
                  Cancel
                </a>
              </div>
            </div>
          </Modal>
        );
    }
}