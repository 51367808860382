import Autocomplete from 'react-autocomplete'
import React from 'react'
import PlayHQMapFinder from './services/PlayHQMapFinder'
import onClickOutside from 'react-onclickoutside'

/**
 * The CentreNameAutocomplete is used to query our api for a list of clubs based on name then display them inside an autocomplete control
 * This component has the following props:
 * isVisible - is this component visible?
 * centreNameInputValue - the value that will be used when querying by club name
 * hasButton - should this component display a button?
 * onCentreNameSelected - callback function that is called when a centre is selected.
 * apiToUse - the api environment to use
 */
class CentreNameAutocomplete extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      centreNameInputValue: props.centreNameInputValue || '',
      centreNameInputItems: [],
      hasButton: props.hasButton || false,
      inputProps: props.inputProps || {},
      hasCancel: props.hasCancel || false,
      noResults: false,
      selectedCentre: null,
      apiToUse: props.apiToUse
    }

    this.state.inputProps['onFocus'] = this.onFocus.bind(this)

    this.inputStyles = {zIndex:110, 'textAlign': 'left', fontSize: '16px', backgroundColor: '#fff'};
    this.inputStylesHighlighted = {zIndex: 110, 'textAlign': 'left', backgroundColor: '#E6EBF3', fontSize: '16px'}
  }

  onFocus(e){
    this.setState({centreNameInputValue: ''})
  }

  componentDidUpdate(){
    if (this.props.isVisible){
      this.nameInput.focus()
    }
  }

  clearAutocomplete(e){
    this.setState({
      centreNameInputValue: '',
      centreNameInputItems: [],
      selectedCentre: null
    })
  }

  handleCentreNameChange(e, value){
    this.setState({
      centreNameInputValue: value,
      locationInputValue: null,
      locationInputId: null,
      centreNameInputItems: [],
    }, ()=>{
      if (this.state.centreNameInputValue.length > 3){
        const programFilter = {
          centreName: this.state.centreNameInputValue,
          program: this.state.program,
          centreId: null,
          apiToUse: this.state.apiToUse
        }
        // call our api
        new PlayHQMapFinder(null, programFilter, this.getCentres.bind(this) )
      }
    })

  }

  handleAutocompleteEscape(ev){
    if(ev.which == 27){
      this.props.hideNameAutocomplete()
    }
  }

  handleClickOutside(e){
    this.props.hideNameAutocomplete()
  }

  renderDropdownItems(items) {
    return items.map((item, index) => {
        return item
    })
  }

  renderDropdown(items, value, style) {
    if (this.state.noResults){
      return (<span></span>)
    }
    else {
      return(
        <div className="autocomplete-items" style={this.menuStyle}>
          {this.renderDropdownItems(items)}
        </div>
      )
    }
  }


  centreNameSelected(value, item){
    this.nameInput.refs.input.blur()

    this.setState({
      centreNameInputValue:  item.name, 
      locationInputId: null,
      centreNameSpanValue: item.name,
      autocompleteVisible: false,
      centreId: item.key
    },() => {
      this.props.onCentreNameSelected(item)
    })
  }

  getCentres(filter, clubs, program){
    this.setState({
      centreNameInputItems: clubs,
    })
  }

  render(){
    let autocompleteClasses = "autocomplete-input-container centre-name-autocomplete"
    if (this.props.isVisible){
      autocompleteClasses += " visible"
    }
    return(
      <div className="relative inline-block centre-name-autocomplete">
        <div className={autocompleteClasses} onKeyUp={this.handleAutocompleteEscape.bind(this)}>
          {this.state.hasButton &&
              <a className="autocomplete-button"></a>
          }
          <Autocomplete
              ref={(input) => { this.nameInput = input; }}
              onChange={this.handleCentreNameChange.bind(this)}
              onSelect={this.centreNameSelected.bind(this)}
              menuStyle={this.autocompleteStyles}
              items={this.state.centreNameInputItems}
              value={this.state.centreNameInputValue}
              getItemValue={(item) => item.name}
              renderMenu={this.renderDropdown.bind(this)}
              autoHighlight={this.state.autocompleteAutoHighlight}
              inputProps={this.state.inputProps}
              renderItem={(item, isHighlighted) => {

                return (
                <div
                  style={isHighlighted ? this.inputStylesHighlighted : this.inputStyles}
                  key={item.key}
                  id={item.key}
                >{item.name} {item.state}</div>
              )}}
            />
            {this.state.hasCancel &&
              this.state.centreNameInputItems.length > 0 &&
              <a onClick={this.clearAutocomplete.bind(this)} className="cancel-autocomplete-icon">
              </a>
            }
            
        </div>
      </div>
    )
  }
}

const WrappedCentreNameAutocomplete = onClickOutside(CentreNameAutocomplete);

export default WrappedCentreNameAutocomplete
