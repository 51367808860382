import React from 'react'
import PropTypes from 'prop-types';
import filterValues from './data/FilterValues'
import FilterPopup from './FilterPopup'
import LocationAutocomplete from './LocationAutocomplete'
import CentreNameAutocomplete from './CentreNameAutocomplete'
import MobileSearchComponent from './MobileSearchComponent'
import AdvancedSearchFiltersComponent from './AdvancedSearchFiltersComponent'
import canUseDOM from "can-use-dom";
import { isMobileOrTabletWidth, isMobileWidth, isMobileOrTabletPortraitWidth } from '../utils'
import { DEFAULT_QUERY_DISTANCE, FORMAT_MAP, DEFAULT_CENTER_SPAN_TEXT, 
  DEFAULT_FORMAT_TEXT, DEFAULT_LOCATION_SPAN_TEXT } from './data/Constants'
import { EventEmitter } from '../events'

/**
The Filter component displays controls that we can use to filter our searches with.

We can filter by age, gender, format and acessibility. We can search by location (suburb) or centreName.

This component can be accessed independently buy setting the "independent" prop to true,
or it can fit within the ClubFinder component.

This component has the following props:
independent: is this component independent or nested inside another component?
programFilter: a programFilter that contains the types of attributes we can use for filtering.  This object is ultimately passed to the 
  search api
gender: what gender are we filtering by? Eg MALE, FEMALE
age: are we filtering by age?
centreNameInputValue: the value to display in the filter component when doing a search by centre name
locationInputValue: the value to display in the filter component when doing a search by location (eg suburb)
goButtonText: Text to display in our Go button
program: an optional array of programs to filter by (eg AUSKICK, COMMUNITY etc)
hideMobileFilter: should the mobile filter be hidden?
onViewTypeChange: A callback function to call then the view type is changed between list and map view
updateFilters: A callback function to call when the filters have been updated
processFilters: A callback function to call when we want to trigger an action like a search, or navigating to a new page
clubFinderBaseUrl: The base url of the club finder page
popupLocation: whether to display popups at the top or bottom of this component

If embedded directly on a page - ie is an independent component, it can read the following properties from the element with id filter_component
gender: eg MALE, FEMALE from the attribute data-gender
program: eg AUSKICK, AFL_9S from the attribute data-format

*/
export default class FilterComponent extends React.Component {
  constructor(props){
    super(props)

    let gender = this.props.gender
    let format = this.props.program || [];
    let apiToUse = null;
    if (this.props.independent){
      // check whether we have been passed values from the html element on the page
      let baseElement = document.getElementById('filter_component')

      let genderProp = baseElement.getAttribute('data-gender')
      if (genderProp){
        gender = genderProp;
      }
      let formatProp = baseElement.getAttribute('data-format')
      if (formatProp){
        format = [formatProp];
      }

      apiToUse = baseElement.getAttribute('data-api-to-use');
    } 
    else if (this.props.programFilter){
      apiToUse = this.props.programFilter.apiToUse
    }

    this.ageInput = React.createRef();
    this.locationSpan = React.createRef();

    this.state = {
      autocompleteVisible: false,
      agesText: 'Add Age',
      genderText: 'Any',
      formatText: DEFAULT_FORMAT_TEXT,
      locationSpanValue: this.props.locationInputValue || DEFAULT_LOCATION_SPAN_TEXT,
      centreNameSpanValue: this.props.centreNameInputValue || DEFAULT_CENTER_SPAN_TEXT,
      locationInputValue: this.props.locationInputValue || '',
      locationInputId: null,
      centreNameInputValue: this.props.centreNameInputValue || '',
      showMobileSearchComponent: false,
      showAdvancedFilterComponent: false,
      isMobileOrTablet: isMobileOrTabletWidth(),
      isMobileOrTabletPortrait: isMobileOrTabletPortraitWidth(),
      isMobile: isMobileWidth(),
      showClubRegoWarning: true,
      isListView: false,
      programFilter: {
        age: this.props.age || '',
        gender: gender,
        accessiblity: false,
        nameSearch: this.props.centreNameInputValue != null,
        program: format,
        centreName: null,
        centreId: null,
        filterCount: 0,
        distance: DEFAULT_QUERY_DISTANCE,
        apiToUse: apiToUse
      },
      autocompleteAutoHighlight: true,
      hideMobileFilter: this.props.hideMobileFilter,
      goButtonText: this.props.goButtonText,
      showGenderPopup: false,
      showFormatPopup: false
    }

    /* Subscribe to when the search type is updated from outside the component eg the header menu */
    EventEmitter.subscribe('searchTypeUpdated', (ev) => this.searchTypeUpdated(ev));

    this._onResize = this.onResize.bind(this);
  }

  /** This component has been updated from outside of the component ie from the top menu items */
  searchTypeUpdated(ev){
    let programFilter = this.state.programFilter;
    if (ev === 'club-search'){
      programFilter.nameSearch = true;
    } else if (ev === 'location-search'){
      programFilter.nameSearch = false;
      programFilter.centreId = null;
      programFilter.centreName = null;

      if (this.props.updateFilters){
        this.props.updateFilters(programFilter); // propogate to parent so we can reset centreId and centreName
      }
    }
    this.setState({ 
      programFilter: programFilter
    })
  }

  getFilterText(items, selectedValue){
    let item = items.find(function(item){
      if (item.value == selectedValue){
        return true;
      }
    })
    if (!item) 
      item = items[0]; // get the first item in the list

    if (this.props.independent)
      return item.displayName
    else{
      if (item.hasOwnProperty('buttonDisplayName'))
        return item.buttonDisplayName;
      else 
        return item.displayName
    }
  }

  getAgeFilterText(items, selectedValue){
    let item = items.find(function(item){
      if (item.value == selectedValue){
        return true;
      }
    })
    if (item == null || typeof item === 'undefined'){
      return '';
    } else if (item.value == null){
      return item.displayName
    }
    else {
      return 'age ' + item.displayName
    }
  }

  toggleViewType(){
    this.setState({
      isListView: ! this.state.isListView
    }, () => {
      if (this.props.onViewTypeChange){
        this.props.onViewTypeChange(this.state.isListView)
      }
    })
  }

  componentDidMount(){
    if (this.props.independent){
      if (canUseDOM && navigator.geolocation) {
        //navigator.geolocation.getCurrentPosition(this.gotLocation.bind(this), this.noLocation.bind(this), {timeout:10000});
        this.setState({
          title: 'Determining your location...'
        })
      } else {
        console.log('Can not determine location.')
      }
    }
    window.addEventListener('resize', this.onResize.bind(this), true)
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.onResize.bind(this))
  }

  onResize(e){
    this.setState({
      isMobileOrTablet: isMobileOrTabletWidth(),
      isMobileOrTabletPortrait: isMobileOrTabletPortraitWidth(),
      isMobile: isMobileWidth(),
      showGenderPopup: false,
      showFormatPopup: false
    }, () => {
      this.render();
    })
  }

  componentWillReceiveProps(nextProps) {
    let programFilter = nextProps.programFilter

    this.setState({
      locationInputValue: nextProps.locationInputValue,
      locationSpanValue: nextProps.locationInputValue,
      centreNameInputValue: programFilter.centreName,
      centreNameSpanValue: programFilter.centreName|| DEFAULT_CENTER_SPAN_TEXT,
      programFilter: programFilter,
      hideMobileFilter: nextProps.hideMobileFilter,
      genderText: this.getFilterText(filterValues.genders, programFilter.gender),
      formatText: this.calculateFormatsText(programFilter.program || [])
    })
  }

  gotLocation(pos){
    //console.log('got location')
    //this.geocodeService.getAddressDetails(pos.coords.latitude, pos.coords.longitude, this.receivedAddress.bind(this));
  }

  noLocation(error){
    this.setState({
        locationInputValue: 'Any Location'
    })
  }

  receivedAddress(geocodeResult){
    this.setState({
      locationInputValue: geocodeResult.address.suburb + ', ' + geocodeResult.address.state
    })
  }

  processGeocodeResults(result){
    this.setState({
      locationInputValue: result.address.suburb + ', ' + result.address.state,
    })
  }

  getOffset(el) {
    el = el.getBoundingClientRect();
    let parent = document.getElementById('map-criteria').getBoundingClientRect()
    return {
      left: el.left - parent.left,
      top: el.top,
      width: el.width,
    }
  }

  showGenderPopup(el){
    if (! this.state.loadingResults){
      let self = this
      this.setState({
        showGenderPopup: true,
        popupPos: self.getOffset(el.target)
      })
    }
  }

  showFormatPopup(el){
    if (! this.state.loadingResults){
      let self = this
      this.setState({
        showFormatPopup: true,
        popupPos: self.getOffset(el.target)
      })
    }
  }

  onPopupCloseClicked(){
    this.setState({
      showGenderPopup: false,
      showFormatPopup: false
    })
  }

  onAgeChanged(ev){
    let programFilter = this.state.programFilter;
    programFilter.age = ev.target.value.replace(/\D/g,'');
    this.setState({programFilter: programFilter})
  }

  onGenderSelected(filterItem){
    let programFilter = this.state.programFilter
    programFilter.gender = filterItem.value
    let genderText = filterValues.genderText;
    if (filterItem.displayHTML){
      genderText = filterItem.displayHTML
    }

    this.setState({
      programFilter: programFilter,
      showGenderPopup: false,
      genderText: genderText,
    })

    if (! this.props.independent){
      if (this.props.processFilters){
        this.props.processFilters(this.state.programFilter, this.state.locationInputId)
      }
    }
  }

  calculateFormatsText(programs){
    let formatText = DEFAULT_FORMAT_TEXT;
    if (programs.length > 0){
      formatText = FORMAT_MAP[programs[0]].title;
      if (programs.length > 1){
        formatText = formatText + ' +' + (programs.length - 1)
      }
    }

    return formatText;
  }

  onFormatSelected(filterItem){
    let programFilter = this.state.programFilter
    let programs = programFilter.program;
    if (filterItem.value === ''){
      programs = [];
    }
    else {
      const index = programs.indexOf(filterItem.value);
      if (index >= 0){
        programs.splice(index, 1);
      } 
      else {
        programs.push(filterItem.value);
      }
    }

    let formatText = this.calculateFormatsText(programs)

    programFilter.program = programs;
    this.setState({
      programFilter: programFilter,
      formatText: formatText
    })

  }

  /** Build our filter url by going through our programFilter object */
  buildFilterUrl(){
    let url = this.props.clubFinderBaseUrl + "?"
    let queryParams = ""
    
    if (this.state.programFilter.age){
      queryParams += "age="+encodeURIComponent(this.state.programFilter.age)
    }

    if (this.state.programFilter.gender){
      if (queryParams.length > 0) queryParams += "&"
      queryParams += "gender="+encodeURIComponent(this.state.programFilter.gender)
    }

    if (this.state.locationInputId){
      if (queryParams.length > 0) queryParams += "&"
      queryParams += "place="+this.state.locationInputId
    }

    if (this.state.programFilter.centreId){
      if (queryParams.length > 0) queryParams += "&"
      queryParams += "centreId="+this.state.programFilter.centreId
    }

    if (this.state.programFilter.program && this.state.programFilter.program.length > 0){
      if (queryParams.length > 0) queryParams += "&"
      queryParams += "program="+ this.state.programFilter.program.join(',');
    }

    return url + queryParams
  }

  onSuburbSelected(item){
    document.getElementById("header").classList.remove('hide')

    this.setState({
      locationSpanValue: item.name,
      autocompleteVisible: false,
      locationInputId: item.place_id
    }, () =>{
      if (! this.props.independent){
        if (this.props.processFilters){
          this.props.processFilters(this.state.programFilter, this.state.locationInputId)
        }
      }
    })
  }

  onCentreNameSelected(selectedCentre){
    document.getElementById("header").classList.remove('hide')
    let programFilter = this.state.programFilter;
    programFilter.centreName = selectedCentre.name;
    programFilter.centreId = selectedCentre.key;
    this.setState({
      centreNameSpanValue: selectedCentre.name,
      centreId: selectedCentre.key,
      nameAutocompleteVisible: false,
      locationInputId: null,
      programFilter: programFilter
    }, () => {
      if (! this.props.independent){
        if (this.props.processFilters){
          this.props.processFilters(this.state.programFilter, this.state.locationInputId,
            this.state.locationInputValue, selectedCentre)
        }
      }
    })
  }

  handleGoButtonClick(e){
    if (this.props.independent){
      let url = this.buildFilterUrl()
      window.location.href = url
    } else {
      if (this.props.processFilters){
        this.props.processFilters(this.state.programFilter, this.state.locationInputId,
          this.state.locationInputValue)
      }
    }
  }

  showAutocomplete(e){
    this.setState({
      autocompleteVisible: true
    })
  }

  hideAutocomplete(){
    this.setState({
      autocompleteVisible: false
    })
  }

  showNameAutocomplete(){
    this.setState({
      nameAutocompleteVisible: true
    })
  }

  hideNameAutocomplete(){
    this.setState({
      nameAutocompleteVisible: false
    })
  }

  activateMobileSearchComponent(e){
    document.getElementById("header").classList.add('hide')
    this.setState({
      showMobileSearchComponent: true
    })
  }

  closeMobileSearchComponent(e){
    document.getElementById("header").classList.remove('hide')
    this.setState({
      showMobileSearchComponent: false
    })
  }

  activateAdvancedFilterComponent(e){
    e.stopPropagation();
    if (this.state.isMobileOrTabletPortrait){
      document.getElementById("header").classList.add('hide');
    }
    document.body.classList.add('noscroll')
    
    document.getElementById("map-criteria").classList.add('is-active')
    this.setState({
      showAdvancedFilterComponent: true
    })
  }

  closeMobileFilterComponent(e){
    document.getElementById("header").classList.remove('hide')
    document.getElementById("map-criteria").classList.remove('is-active')
    document.body.classList.remove('noscroll')
    this.setState({
      showAdvancedFilterComponent: false
    })
  }

  onMobileSearchSuburbSelected(item){
    this.setState({
      locationSpanValue: item.name,
      locationInputValue:  item.name,
      locationInputId: item.place_id,
      showMobileSearchComponent: false
    }, () =>{
      if (! this.props.independent){
        document.getElementById("header").classList.remove('hide')
        if (this.props.processFilters){
          this.props.processFilters(this.state.programFilter, this.state.locationInputId)
        }
      } else {
        let url = this.buildFilterUrl()
        window.location.href = url
      }
    })
  }

  /** User has selected a centre from the mobile component */
  onMobileSearchCentreNameSelected(selectedCentre){
    let programFilter = this.state.programFilter;
    programFilter.nameSearch = true
    programFilter.centreName = selectedCentre.name;
    programFilter.centreId = selectedCentre.key;

    this.setState({
      centreNameSpanValue: selectedCentre.name,
      centreId: selectedCentre.key,
      programFilter: programFilter,
      locationInputId: null,
      showMobileSearchComponent: false,
      centreNameInputValue: selectedCentre.name
    }, () =>{
      if (! this.props.independent){
        document.getElementById("header").classList.remove('hide')
        document.getElementById("map-criteria").classList.remove('is-active')

        if (this.props.processFilters){
          this.props.processFilters(this.state.programFilter, this.state.locationInputId,
            this.state.locationInputValue, selectedCentre)
        }
      } else {
        let url = this.buildFilterUrl()
        window.location.href = url
      }
    })
  }

  onMobileFilterSearch(programFilter){
    this.setState({
      programFilter: programFilter,
      showAdvancedFilterComponent: false
    }, () =>{
      if (! this.props.independent){
        document.getElementById("header").classList.remove('hide')
        document.getElementById("map-criteria").classList.remove('is-active')
        if (this.props.processFilters){
          this.props.processFilters(this.state.programFilter, this.state.locationInputId)
        }
      } else {
        let url = this.buildFilterUrl()
        window.location.href = url
      }
    })
  }

  render(){
    let mapClasses = ""
    if (this.props.independent == false){
      mapClasses += "map-criteria"
    }

    if (this.props.hideMobileFilter){
      mapClasses += " hide-filter"
    }

    return (
      <div className={mapClasses} id="map-criteria">

        {this.props.independent &&
          this.renderLandingPageFilters()
        }

        {! this.props.independent &&
          this.renderStandardFilters()
        }
          <div className="popup-wrapper">

          {this.state.showFormatPopup &&
              <FilterPopup
                name="format"
                values={filterValues.formats}
                onPopupCloseClicked={this.onPopupCloseClicked.bind(this)}
                onFilterValue={this.onFormatSelected.bind(this)}
                pos={this.state.popupPos}
                popupLocation={this.props.popupLocation}
                selectedValues={this.state.programFilter.program}
              />
            }

            {this.state.showGenderPopup &&
              <FilterPopup
                name="gender"
                values={filterValues.genders}
                onPopupCloseClicked={this.onPopupCloseClicked.bind(this)}
                onFilterValue={this.onGenderSelected.bind(this)}
                pos={this.state.popupPos}
                popupLocation={this.props.popupLocation}
                selectedValues={[this.state.programFilter.gender]}
              />
            }
            
            {this.state.showMobileSearchComponent &&
              <MobileSearchComponent
                programFilter={this.state.programFilter}
                locationInputValue={this.state.locationInputValue}
                centreNameInputValue={this.state.centreNameInputValue}
                onSuburbSelected={this.onMobileSearchSuburbSelected.bind(this)}
                onCentreNameSelected={this.onMobileSearchCentreNameSelected.bind(this)}
                showNearby={this.props.independent}
                close={this.closeMobileSearchComponent.bind(this)}
              >
              </MobileSearchComponent>
            }
            {this.state.showAdvancedFilterComponent &&
              <div className="finder-modal-overlay">
                <AdvancedSearchFiltersComponent
                  programFilter={this.state.programFilter}
                  onFilterSearch={this.onMobileFilterSearch.bind(this)}
                  close={this.closeMobileFilterComponent.bind(this)}
                >
                </AdvancedSearchFiltersComponent>
              </div>
            }
          </div>
      </div>
    )
  }

  onLocationTabClick(e){
    let programFilter = this.state.programFilter
    programFilter.nameSearch = false;
    this.setState({programFilter: programFilter}, () => {
      this.doFilterFocused();
      if (this.props.doLocationSearch){
        this.props.doLocationSearch()
      }
    })
  }

  onNameTabClick(e){
    let programFilter = this.state.programFilter
    programFilter.nameSearch = true;
    this.setState({programFilter: programFilter}, () => {
      this.doFilterFocused();
      if (this.props.doNameSearch){
        this.props.doNameSearch()
      }
    })
  }

  doFilterFocused(){
    if (this.props.doFilterFocused){
      this.props.doFilterFocused();
    }
  }

  focusAgeInput(e){
    this.ageInput.current.focus()
  }

  focusLocationSpan(e){
    if (this.locationSpan && this.locationSpan.current){
      this.locationSpan.current.focus()
    }
    
  }

  handleEnterPress(e){
    if (e.which === 13){
      this.handleGoButtonClick(e);
    }
  }

  handleAdvFiltersEnterPress(e){
    if (e.which === 13){
      this.activateAdvancedFilterComponent(e);
    }
  }

  renderGoButton(){
    return (
      <button className="btn btn-primary btn-go btn-filter-search" onClick={this.handleGoButtonClick.bind(this)} 
              onKeyPress={this.handleEnterPress.bind(this)} tabIndex="0" role="button">{this.props.goButtonText}</button>
    )
  }

  renderDesktopFilters(nameValueClass){
    return (
      <div className="program-filter-container">
        
          { this.state.programFilter.nameSearch == false &&
            <div className="program-filter-outer">
              <div className="program-filters">
                <div className="location-filters-section filter-section">
                  <span className="filter-text">Location</span>
                  <span className="location-value" ref={this.locationSpan} onClick={this.showAutocomplete.bind(this)} onFocus={this.showAutocomplete.bind(this)} tabIndex="0">
                    {this.state.locationSpanValue}
                  </span>

                  <LocationAutocomplete
                    onSuburbSelected={this.onSuburbSelected.bind(this)}
                    doFilterFocused={this.doFilterFocused.bind(this)}
                    locationInputValue={this.state.locationInputValue}
                    isVisible={this.state.autocompleteVisible}
                    hideAutocomplete={this.hideAutocomplete.bind(this)}
                    programFilter={this.state.programFilter}
                    ></LocationAutocomplete>
                </div>

                <div className="format-filters-section filter-section">
                  <span className="filter-text">Format</span>
                  <a onClick={(el)=> this.showFormatPopup(el)} onFocus={(el)=> this.showFormatPopup(el)} tabIndex="0">
                    {this.state.formatText}
                    </a>
                </div>

                <div className="team-filters-section filter-section">
                  <span className="filter-text">Team</span>
                  <a onClick={(el)=> this.showGenderPopup(el)} onFocus={(el)=> this.showGenderPopup(el)} tabIndex="0">
                    {this.state.genderText}
                    </a>
                </div>

                
                {this.renderGoButton()}
            </div>
            <button className="advanced-filter-button" onClick={this.activateAdvancedFilterComponent.bind(this)} onKeyPress={this.handleAdvFiltersEnterPress.bind(this)} tabIndex="0"><span>More Filters</span></button>
          </div>
        }
        { this.state.programFilter.nameSearch &&
          <div className="program-filters">
            <div className="name-filters-section filter-section">
              <span className="filter-text">Club/Centre</span>
              <span className={"name-filter-results " +nameValueClass}
                onClick={this.showNameAutocomplete.bind(this)} tabIndex="0">
                {this.state.centreNameSpanValue}
              </span>

              <CentreNameAutocomplete
                onCentreNameSelected={this.onCentreNameSelected.bind(this)}
                centreNameInputValue={this.state.centreNameInputValue}
                isVisible={this.state.nameAutocompleteVisible}
                hideNameAutocomplete={this.hideNameAutocomplete.bind(this)}
                program={this.state.programFilter.program}
                apiToUse={this.state.programFilter.apiToUse}
              >
              </CentreNameAutocomplete>
            </div>
            {this.renderGoButton()}
          </div>
        }
      </div>
    );
  }

  renderLandingPageFilters(){
    let locationTabActive = " active"
    let nameTabActive = "";

    if (this.state.programFilter.nameSearch){
      locationTabActive = ""
      nameTabActive = " active"
    }

    let nameValueClass = "name-value"
    if (this.state.programFilter.centreName && this.state.programFilter.centreName.length > 0){
      nameValueClass += " entered"
    }
    return (
      <div>
        {this.state.isMobileOrTabletPortrait &&
          <div className="program-filter-container filter-button-container">
            <a className="btn btn-find-place" onClick={this.activateMobileSearchComponent.bind(this)}>
              <span className="magnifying-glass-icon"></span> Find your place to play
            </a>
          </div>
        }
        {! this.state.isMobileOrTabletPortrait &&
          this.renderDesktopFilters(nameValueClass)
        }
      </div>
    )
  }

  /**
    Renders the standard filters for the map finder page
  */
  renderStandardFilters(){

    let filterCount = 0;
    if (this.state.programFilter.age){
      filterCount++;
    }
    if (this.state.programFilter.gender){
      filterCount++;
    }
    if (this.state.programFilter.accessiblity){
      filterCount++;
    }
    if (this.state.programFilter.program && this.state.programFilter.length > 0){
      filterCount++;
    }

    let filterClass = "mobile-map-filter-button";
    if (filterCount > 0){
      filterClass += " filter-on"
    }

    let viewTypeButton = "mobile-map-view-type-button";
    if (this.state.isListView){
      viewTypeButton = viewTypeButton + " map-button"
    }

    return (
      <div className="club-finder-filter-component">
        {this.state.isMobileOrTabletPortrait &&
          ! this.state.showAdvancedFilterComponent &&
          <div className="program-filter-container filter-button-container">
            <div className="mobile-map-filter-container">
              <div className="mobile-map-filter-inner" onClick={this.activateMobileSearchComponent.bind(this)}>
                {this.state.programFilter.nameSearch &&
                  <span className="name-value"
                     tabIndex="3">
                    {this.state.centreNameInputValue}
                  </span>
                }
                {! this.state.programFilter.nameSearch &&
                  <span className="location-value" tabIndex="0" >
                    {this.props.locationInputValue || this.state.locationSpanValue}
                  </span>
                }
                
              </div>
              <div className={filterClass} onClick={this.activateAdvancedFilterComponent.bind(this)} title="Add Filters" tabIndex="0">
              </div>
              <div className={viewTypeButton} onClick={this.toggleViewType.bind(this)} title="Change the type of view" tabIndex="0">
                
              </div>
            </div>
          </div>
        }
        {! this.state.isMobileOrTabletPortrait &&
          this.renderDesktopFilters("")
      }
      </div>
    )
  }
}


FilterComponent.propTypes = {
  independent: PropTypes.bool, // if true then this is a separate component from the map
  processFilters: PropTypes.func,
  updateFilters: PropTypes.func,
  clubFinderBaseUrl: PropTypes.string,
  age: PropTypes.string,
  gender: PropTypes.string,
  ability: PropTypes.string,
  locationInputValue: PropTypes.string,
  centreNameInputValue: PropTypes.string,
  popupLocation: PropTypes.string,
  goButtonText: PropTypes.string,
  hideMobileFilter: PropTypes.bool
}

FilterComponent.defaultProps = {
  independent: false, // if true then this is a separate component from the map
  processFilters: null,
  updateFilters: null,
  clubFinderBaseUrl: "club-finder/",
  age: '',
  gender: null,
  ability: null,
  locationInputValue: null,
  centreNameInputValue: null,  // if null defaults to location search
  popupLocation: 'top',
  goButtonText: "",
  hideMobileFilter: false,
}
