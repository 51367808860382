import React from 'react'
import { MAP_IMAGES_DIR, FORMAT_MAP } from "./data/Constants"

/* AdvancedSearchFiltersComponent 
  Component that displays additional filter controls for the user.

  Accepts the following props:
  programFilter: programFilters map which will be ultimately passed to the search api
  close: callback function that is called when the user triggers for the component to be closed
  onFilterSearch: callback function that is called when the user triggers a search.

*/
export default class AdvancedSearchFiltersComponent extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      programFilter: this.props.programFilter,
      ageContainerStyle: null
    }
  }

  componentDidMount(){
    this.ageInput = React.createRef();
  }

  onAgeChanged(ev){
    let programFilter = this.state.programFilter;
    programFilter.age = ev.target.value.replace(/\D/g,'');
    this.setState({programFilter: programFilter})
  }

  onAcessibilityChanged(ev){
    let programFilter = this.state.programFilter;
    programFilter.accessibility = ! programFilter.accessibility;
    this.setState({programFilter: programFilter})
  }

  selectGender(gender){

    let programFilter = this.state.programFilter;
    if (programFilter.gender === gender){
      programFilter.gender = '';
    } else {
      programFilter.gender = gender
    }
    
    this.setState({
      programFilter: programFilter
    })
  }

  selectAllTeams(e){
    this.selectGender(null)
  }

  selectMixedTeams(e){
    this.selectGender('MIXED')
  }

  selectFemaleTeams(e){
    this.selectGender('FEMALE')
  }

  selectMaleTeams(e){
    this.selectGender('MALE')
  }

  /* Turn the selected format on or off */
  toggleFormat(format){
    let programFilter = this.state.programFilter;
    let programs = programFilter.program;
    const index = programs.indexOf(format);
    if (index >= 0){
      programs.splice(index, 1);
    } 
    else {
      programs.push(format);
    }
    programFilter.program = programs;
    this.setState({
      programFilter: programFilter
    })
  }

  selectAuskick(e){
    this.toggleFormat('AUSKICK')
  }

  selectCommunity(e){
    this.toggleFormat('COMMUNITY')
  }

  selectAFL9s(e){
    this.toggleFormat('AFL_9S')
  }

  selectAFL9X(e){
    this.toggleFormat('AFLX')
  }

  handleCloseKeypress(e){
    if (e.which === 13){
      if (this.props.close){
        this.props.close();
      }
    }
  }

  clearFilters(e){
    let programFilter = this.state.programFilter;
    programFilter.gender = null;
    programFilter.age = '';
    programFilter.program = [];
    programFilter.accessibility = false;
    this.setState({
      programFilter: programFilter
    })
  }

  doFilter(e){
    this.props.onFilterSearch(this.state.programFilter)
  }

  handleClearKeypress(e){
    if (e.which === 13){
      this.clearFilters(e)
    }
  }

  handleSearchKeypress(e){
    if (e.which === 13){
      if (this.props.onFilterSearch){
        this.props.onFilterSearch(this.state.programFilter)
      }
    }
  }

  render(){
    let programs = this.state.programFilter.program || [];

    return (
      <div className="advanced-filter-component">
        <div className="advanced-filter-header">
          <div className="close-component-container">
            <a onClick={this.props.close.bind(this)} className="close-component" 
            onKeyPress={this.handleCloseKeypress.bind(this)} title="Close" tabIndex="0"></a>
          </div>
          <h3>Filter Results</h3>
          <a className="clear-results-link-mobile" onClick={this.clearFilters.bind(this)} 
          onKeyPress={this.handleClearKeypress.bind(this)} tabIndex="0">Clear All</a>
        </div>
        

        <div className="advanced-filter-container">
          <div className="advanced-filter-inner">
            <h4>Format</h4>
            <div className="program-format-container flex flex-wrap">
              <div className="program-format-button-wrapper button-wrapper">
                <a className={programs.indexOf('AUSKICK') >= 0 ? "btn btn-selected": "btn"} onClick={this.selectAuskick.bind(this)} tabIndex="0">
                  <img src={MAP_IMAGES_DIR + 'logos/' + FORMAT_MAP['AUSKICK'].logo_2x} alt={FORMAT_MAP['AUSKICK'].imgTitle} />
                  <p dangerouslySetInnerHTML={{__html: FORMAT_MAP['AUSKICK'].description}}></p>
                </a>
              </div>
              <div className="program-format-button-wrapper button-wrapper">
                <a className={programs.indexOf('COMMUNITY') >= 0 ? "btn btn-selected": "btn"} onClick={this.selectCommunity.bind(this)} tabIndex="0">
                  <img src={MAP_IMAGES_DIR + 'logos/' + FORMAT_MAP['COMMUNITY'].logo_2x} alt={FORMAT_MAP['COMMUNITY'].imgTitle} />
                  <p dangerouslySetInnerHTML={{__html: FORMAT_MAP['COMMUNITY'].description}}></p>
                </a>
              </div>
              <div className="program-format-button-wrapper button-wrapper">
                <a className={programs.indexOf('AFL_9S') >= 0 ? "btn btn-selected": "btn"} onClick={this.selectAFL9s.bind(this)} tabIndex="0">
                  <img src={MAP_IMAGES_DIR + 'logos/' + FORMAT_MAP['AFL_9S'].logo_2x} alt={FORMAT_MAP['AFL_9S'].imgTitle} />
                  <p dangerouslySetInnerHTML={{__html: FORMAT_MAP['AFL_9S'].description}}></p>
                </a>
              </div>
              <div className="program-format-button-wrapper button-wrapper">
                <a className={programs.indexOf('AFLX') >= 0 ? "btn btn-selected": "btn"} onClick={this.selectAFL9X.bind(this)} tabIndex="0">
                  <img src={MAP_IMAGES_DIR + 'logos/' + FORMAT_MAP['AFLX'].logo_2x} alt={FORMAT_MAP['AFLX'].description} />
                  <p dangerouslySetInnerHTML={{__html: FORMAT_MAP['AFLX'].description}}></p>
                </a>
              </div>
            </div>

            <div className="filter-group-container">
              <h4>Team Type</h4>
              <div className="team-type-button-container flex flex-wrap">
                <div className="team-type-button-wrapper button-wrapper">
                  <a className={this.state.programFilter.gender == 'FEMALE' ? "btn btn-selected" : "btn"} onClick={this.selectFemaleTeams.bind(this)} tabIndex="0">
                    Female <span className="icon-selected"></span>
                  </a>
                </div>
                <div className="team-type-button-wrapper button-wrapper">
                  <a className={this.state.programFilter.gender == 'MALE' ? "btn btn-selected" : "btn" }  onClick={this.selectMaleTeams.bind(this)} tabIndex="0">
                    Male <span className="icon-selected"></span>
                  </a>
                </div>
                
              </div>
              <p className="small">You can play for any team no matter your gender identity</p>
            </div>
            
            <div className="age-container filter-group-container">
              <h4>Age</h4>
              <div className="age-container-inner" style={this.state.ageContainerStyle}>
              <input type="number" className="age-input" ref={this.ageInput } value={this.state.programFilter.age} placeholder="Your Age" onChange={this.onAgeChanged.bind(this)} tabIndex="0"/>
              </div>
            </div>

            {/* <div className="accessibility-toggle-container flex filter-group-container">
              <h4>Accessibility</h4>
              <label className="toggle-switch">
                <input type="checkbox" checked={this.state.programFilter.accessibility} onChange={this.onAcessibilityChanged.bind(this)}/>
                <span className="slider round"></span>
              </label>
            </div>
            <p className="small">Find a centre or club accredited for ‘Access All Abilities’</p> */}
          </div>

          <div className="button-container">
            <a className="clear-results-link" onClick={this.clearFilters.bind(this)} onKeyPress={this.handleClearKeypress.bind(this)} tabIndex="0">Clear All</a>
            <a className="btn btn-primary btn-mobile-filter-results" onClick={this.doFilter.bind(this)} 
              onKeyPress={this.handleSearchKeypress.bind(this)} tabIndex="0" >Show Results</a>
          </div>

        </div>
      </div>
    )
  }
}
