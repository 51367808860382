import { MAP_PIN, SEARCH_ENVIRONMENTS, ACCESS_FOR_ALL_ABILITIES_PLAY
} from '../data/Constants'
import axios from 'axios'
import { deg2rad } from '../../utils'
import '../poly/includes.js'
import RegisterService from './RegisterService'

/*
    Our main interface with the playhq api.
    Generates an elasticsearch query then retrieves a list of clubs.
*/
class PlayHQMapFinder {
    constructor(center, programFilter, callback){
        this.callback = callback
        this.center = center;
        this.programFilter = programFilter;

        let api = this.programFilter.apiToUse;
        if (api && api in SEARCH_ENVIRONMENTS){
            this.searchURL = SEARCH_ENVIRONMENTS[api].url;
            this.username = SEARCH_ENVIRONMENTS[api].user;
            this.password = SEARCH_ENVIRONMENTS[api].pass;
            this.registerUrlBase = SEARCH_ENVIRONMENTS[api].registerBaseUrl
        } else {
            alert("API environment not correctly configured!");
            return;
        }
        try{
            this.findClubs(center, programFilter);
        } catch (e){
            console.log(e)
        }
    }

    /** Determines how to query for clubs */
    findClubs(geoLocation, programFilter){
        if (programFilter.centreId !== null){
            this.findClubById(programFilter.centreId); // we are after an individual club.
        }
        else if (programFilter.centreName !== null && programFilter.centreName.length > 0){
            this.findClubsByName(programFilter.centreName); // search by name
        } else {
            this.findClubsByLocation(geoLocation, programFilter); // location based search
        }
    }

    findClubsByName(centreName){
        let query = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "wildcard": {
                                "textSearch.lowercase": "*" + centreName.toLowerCase() + "*"
                            }
                        },
                        // {"match": {"type": "CLUB"}}, 
                        { "match": { "includeInFinder": true } },
                        { "match": { "visible": true } }
                    ]
                }
                
                
              }
        }
      
        this.doSearch(query)  
    }

    findClubById(centreId){
        let query = {
            "from" : 0, "size" : 1,
            "query": {
                "match": { "id": {"query": centreId} }
            }
        }

        this.doSearch(query)  
    }

    /* Generate our subcondition for gender.
    If the user has selected MALE or FEMALE we add an ALL subclause */
    addGenderConditions(programFilter){
        let ageGenderConditions = [{
            "bool": {
                "must": [
                    {"match": { "demographics.gender": { "query": programFilter.gender }}}
                ]
            }
        }]

        if (programFilter.gender === 'MALE' || programFilter.gender === 'FEMALE'){
            // include teams that allow ALL genders
            ageGenderConditions.push({
                "bool": {
                    "must": [
                        {"match": { "demographics.gender": { "query": "ALL" }}}
                    ]
                }
            });
        }

        return {
            "nested": {
                "path": "demographics",
                "query": {
                    "bool": {
                        "should": ageGenderConditions
                    }
                }
            }
        }
    }

    /** Add the Age subcondition.  If the user has also selected a gender, filter on the appropriate gender with the selected age */
    addAgeConditions(programFilter){
        let ageGenderConditions = [];
        if (programFilter.gender && programFilter.gender.length > 0){
            ageGenderConditions.push({
                "bool": {
                    "must": [
                        {"range": { "demographics.ageFrom": { "lte": Number(programFilter.age) } } },
                        {"range": { "demographics.ageTo": { "gte": Number(programFilter.age)  } } },
                        {"match": { "demographics.gender": { "query": programFilter.gender }}}
                    ]
                }
            });

            if (programFilter.gender === 'MALE' || programFilter.gender === 'FEMALE'){
                // include teams that allow ALL genders
                ageGenderConditions.push({
                    "bool": {
                        "must": [
                            {"range": { "demographics.ageFrom": { "lte": Number(programFilter.age) } } },
                            {"range": { "demographics.ageTo": { "gte": Number(programFilter.age)  } } },
                            {"match": { "demographics.gender": { "query": "ALL" }}}
                        ]
                    }
                });
            }
        } 
        else {
            ageGenderConditions.push({
                "bool": {
                    "must": [
                        {"range": { "demographics.ageFrom": { "lte": Number(programFilter.age) } } },
                        {"range": { "demographics.ageTo": { "gte": Number(programFilter.age)  } } },
                    ]
                }
            });
        } 

        return {
            "nested": {
                "path": "demographics",
                "query": {
                    "bool": {
                        "should": ageGenderConditions
                    }
                }
            }
        }
        
    }

    /** Add filters for our formats/programs */
    addFormatsConditions(programFilter){
        let formatConditions = [];
        programFilter.program.forEach((program) => {
            formatConditions.push({"match": { "formats.format": {"query": program } } });
        })
        return {
            "nested": {
                "path": "formats",
                "query": {
                    "bool": {
                        "should": formatConditions
                    }
                }
            }
        }
    }

    findClubsByLocation(geoLocation, programFilter){

        let latlong = { "lat": geoLocation.lat, "lon": geoLocation.lng };

        let filter = [
            {"geo_distance": { "distance": this.programFilter.distance, "geoLocation": latlong } },
            // {"match": {"type": "CLUB"}},
          { "match": { "includeInFinder": true } },
            { "match": { "visible": true } }
        ];

        if (programFilter.age && programFilter.age.length > 0){
            filter.push(this.addAgeConditions(programFilter))
        } 
        else if (programFilter.gender && programFilter.gender.length > 0){
            // Only do this if we haven't already done it in our age subcondition
            filter.push(this.addGenderConditions(programFilter))
        }

        if (programFilter.accessibility){
            filter.push({"match": { "inclusions": {"query": ACCESS_FOR_ALL_ABILITIES_PLAY}}})
        }

        if (programFilter.program && programFilter.program.length > 0){
            filter.push(this.addFormatsConditions(programFilter))
        }

        let query = {
            // sort by distance from our central point
            "sort" : [
                {
                    "_geo_distance" : {
                        "geoLocation" : latlong,
                        "order" : "asc",
                        "unit" : "km",
                        "mode" : "min",
                        "distance_type" : "arc",
                        "ignore_unmapped": true
                    }
                }
            ],
            "query": {
                "bool": {
                    "must": filter
                }
            }
        }

        this.doSearch(query)        
    }

    doSearch(query){ 
        axios.get(this.searchURL, {
            params: {
                source: JSON.stringify(query),
                source_content_type: 'application/json'
            },
            auth: {
                username: this.username,
                password: this.password
            }
        }).then(this.processResults.bind(this))
        .catch((e) => {
            console.log(e);
            this.callback(this.filter, [], ['An Error Occurred'])
        });
    }

    processResults(response){
        // console.log(response);
        var clubs = []
        if (response.status === 200){
            response.data.hits.hits.forEach((result) => {
                let pos = null;
                if(result._source.geoLocation){
                    pos = {
                        lng: Number(result._source.geoLocation.lon),
                        lat: Number(result._source.geoLocation.lat)
                    }
                }
                
                let name = result._source.name;
                let icon = MAP_PIN;

                let distance = 0;
                
                if (this.center){
                    distance = this.getDistanceFromLatLonInKm(this.center.lat, this.center.lng, pos.lat, pos.lng)
                }

                let registerWithGameday = false; // TODO: find out which field is used to determine whether a club is still with Gameday
                let baseRegisterUrl = SEARCH_ENVIRONMENTS[this.programFilter.apiToUse].registerBaseUrl;

                if ('registrationPlatform' in result._source){
                    if (result._source.registrationPlatform !== 'PlayHQ'){
                        registerWithGameday = true;
                        baseRegisterUrl = result._source.registrationUrl
                    }
                }
                
                var clubRegoFunc = new RegisterService(registerWithGameday, baseRegisterUrl, name, result._source.id);
                clubs.push({
                    key: result._source.id,
                    name: name,
                    registerFunc: clubRegoFunc,
                    position: pos,
                    distance: Number(distance).toFixed(1),
                    distanceNum: Number(distance),
                    details: result._source,
                    icon: icon,
                })
            });

            this.callback(this.filter, clubs)
        } else {
            // todo log error
            this.callback(this.filter, clubs, ['An Error'])
        }
    }

    /* Calculate the distance from our origin point */
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    }
}

export default PlayHQMapFinder
