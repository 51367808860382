import React from 'react'
import LocationAutocomplete from './LocationAutocomplete'
import CentreNameAutocomplete from './CentreNameAutocomplete'

/*
  The MobileSearchComponent is a component for mobile devices that enables 
  the user to select between searching by location or by centre name.
  It accepts the following properties:
  programFilter - the programFilter object which is used to query the api
  locationInputValue - text to display for the location
  centreNameInputValue - text to display for the centre name
  showNearby - should we show the "nearby" listing in the location search?
  locationInputProps - properties for the input in the location autocomplete
  onSuburbSelected - callback function to call for when a location has been selected from the location autocomplete
  onCentreNameSelected - callback function to call for when a center name has been selected from the centre name autocomplete
*/
export default class MobileSearchComponent extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      nameSearch: this.props.programFilter.nameSearch,
      locationInputValue: this.props.locationInputValue || '',
      centreNameInputValue: this.props.centreNameInputValue || '',
      apiToUse: this.props.programFilter.apiToUse,
      program: this.props.program,
      locationInputProps:{
        placeholder: 'Suburb or postcode'
      },
      nameInputProps:{
        placeholder: 'Club or centre name'
      },
      programFilter: this.props.programFilter,
    }
  }

  hideAutocomplete(e){
    // do nothing
  }

  onLocationTabClick(e){
    this.setState({
      nameSearch: false
    });
  }

  onNameTabClick(e){
    this.setState({
      nameSearch: true
    });
  }

  render(){
    let locationTabActive = " active"
    let nameTabActive = "";

    if (this.state.nameSearch){
      locationTabActive = ""
      nameTabActive = " active"
    }

    return(
      <div className="mobile-expanding-autocomplete-container expanded mobile-search-component">
        
        <div className="program-filter-container">
          <a onClick={this.props.close.bind(this)} className="close-component" title="Close" tabIndex="0"></a>
          <div className="program-filter-tabs">
            <div className={"program-filter-tab location-search-tab" + locationTabActive } onClick={this.onLocationTabClick.bind(this)} tabIndex="0">
              <span>Places to Play</span>
            </div>
            <div className={"program-filter-tab name-search-tab" + nameTabActive }  onClick={this.onNameTabClick.bind(this)} tabIndex="0">
              <span>Search Clubs</span>
            </div>
          </div>
          <div className="mobile-expanding-autocomplete-inner">
            {! this.state.nameSearch &&
              <LocationAutocomplete
                onSuburbSelected={this.props.onSuburbSelected.bind(this)}
                locationInputValue={this.state.locationInputValue}
                isVisible={true}
                hideAutocomplete={this.hideAutocomplete.bind(this)}
                hasButton={true}
                hasCancel={true}
                showNearby={this.props.showNearby}
                inputProps={this.state.locationInputProps}
                programFilter={this.state.programFilter}
                tabIndex="0"
                ></LocationAutocomplete>
            }
            {this.state.nameSearch &&
              <CentreNameAutocomplete
                onCentreNameSelected={this.props.onCentreNameSelected.bind(this)}
                centreNameInputValue={this.state.centreNameInputValue}
                isVisible={true}
                hideNameAutocomplete={this.hideAutocomplete.bind(this)}
                hasButton={true}
                hasCancel={true}
                inputProps={this.state.nameInputProps}
                program={this.state.program}
                apiToUse={this.state.apiToUse}
              >
              </CentreNameAutocomplete>
            }
          </div>
        </div>
      </div>
    );
  }
}
