import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom'
import { initNavSearch } from './nav-search'
/* 
If using the old gameday api, uncomment this and comment out the other Filter component*/
import FilterComponent from './club-finder/FilterComponent'; 

/* 
If using the new PlayHQ api, uncomment this and comment out the other Filter component 
import FilterComponent from './gameday-club-finder/FilterComponent'*/

/**
Filter component
*/
var filterEl = document.getElementById('filter_component');
if (filterEl){
  
  initNavSearch();
  ReactDOM.render(
    <FilterComponent independent={true} />,
    filterEl,
    
  );
  
}