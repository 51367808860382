import { GAMEDAY_DEFAULT_REGISTER_LINK} from '../data/Constants'
import TransitionModal from '../../components/modals/TransitionModal'
import ReactDOM from 'react-dom'
import React from 'react'
import { stringToSlug } from '../../utils'

/*
A convenience class that determines the correct url to send the user, then displays a transition modal
before sending the user to the new location.  
*/
export default class RegisterService {

  /*
    @param registerWithGameday - if true use gameday, otherwise playhq
    @param baseRegisterUrl - if registerWithGameday is true, then this is the url to send the user to for registering, otherwise generate one for playhq
    @param clubName - the name of the club - used for generating the playhq url
    @param uuid - the uuid of the club - used for generating the playhq url
  */
  constructor(registerWithGameday, baseRegisterUrl, clubName, uuid){
    this.registerWithGameday = registerWithGameday;
    this.baseRegisterUrl = baseRegisterUrl;
    this.clubName = clubName;
    this.uuid = uuid;
  }

  determineRegistrationUrl(){
    if (this.registerWithGameday){
      this.openTransitionModalAndGo(this.baseRegisterUrl, "GameDay", 'gameday'); // send people to our default gameday link
    } 
    else {
      let clubSlug = stringToSlug(this.clubName); // fortunately the server doesn't actually check this, but lets generate something nice.
      let uuidSegment = this.uuid.substr(0, 8); // get the first 8 digits
      let regUrl = this.baseRegisterUrl + clubSlug + '/' + uuidSegment + '/register';
      this.openTransitionModalAndGo(regUrl, "Play HQ", 'playhq');
    }
  }

  openTransitionModalAndGo(regUrl, destinationName, logoClass){
     
    let props = {
      open: true,
      url: regUrl,
      destinationName: destinationName,
      logoClass: logoClass
    }

    ReactDOM.render(<TransitionModal {...props} />, document.getElementById("modal-container"));
  }
}
